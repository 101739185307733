import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CounterUpComponent from "../components/CounterUpComponent";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Successstories = () => {
  const sendmail = () => {
    var link =
      "mailto:info@myprega.com" +
      "?cc=" +
      "&subject= About subscription" +
      "&body=" +
      encodeURIComponent("\n");
    window.location.href = link;
  };
  const options = {
    items: 2,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    dots: false,
    nav: false,

    responsive: {
      0: { items: 1 },
      576: { items: 1 },
      768: { items: 1 },
      1200: { items: 2 },
    },
  };
  return (
    <>
      <div
        style={{
          backgroundImage: 'url("/heroimg3.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",

          width: "100%",
          // backgroundAttachment: 'fixed',
        }}
      >
        <div className="container py-5">
          <div className="">
            <h3 className="hero-title text-center">
              Our success stories:
              <span className="blue">
                {" "}
                From the voice of mom and mom buddies
              </span>
            </h3>
            <h4 className="underlineone text-secondary hero_subtitle text-center d-flex align-items-center justify-content-center m-auto">
              Don't just take our word for it, Our moms love our care just as
              much as we do
            </h4>
            
            <span className="mb-3 pl-3">
              <br />
            </span>
            <div className="container bg-secondary"></div>
            <div className="container">
              <div className="row py-5 align-items-center">
                <div className="col-md-8">
                  <OwlCarousel className="owl-theme" {...options}>
                    <div className="item abc">
                      <div className="square bg-white">
                        <p className="marginadj">
                          I like the easy access to my <br />
                          daily pregnancy records, alerts on when consultation
                          and labs are due, and the opportunity to ask questions
                          anytime. myprega simplified my pregnancy journey.
                        </p>
                        <div className="star">
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                        </div>
                        <p className="nm"></p>
                      </div>

                      <div className="small-square">
                        <img src="/customer1.jpg" className="img-fluid" />
                      </div>
                    </div>
                    <div className="item abc">
                      <div className="square bg-white">
                        <p className="text-left">
                          The responsibility of caring for pregnant mothers lies
                          on the shoulders of family, public health systems and
                          on volunteers, who often struggle to navigate
                          unorganised care and myprega is the only solution for
                          this problem.
                        </p>
                        <div className="star">
                          <i className="bi bi-star-fill as pl-4"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                        </div>
                        <p className="nm"></p>
                      </div>

                      <div className="small-square">
                        <img src="/customer3.jpg" className="img-fluid" />
                      </div>
                    </div>
                    <div className="item abc">
                      <div className="square bg-white">
                        <p className="text-left">
                          myprega is changing the traditional pregnancy care
                          with their technology driven solution and I'm proud to
                          be pioneers for this vision.
                        </p>
                        <div className="star sp">
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                        </div>
                        <p className="nm"></p>
                      </div>

                      <div className="small-square">
                        <img src="/customer4.jpg" className="img-fluid" />
                      </div>
                    </div>
                    <div className="item abc">
                      <div className="square bg-white">
                        <p className="text-left">
                          At myprega, nothing is out of reach when it comes to
                          getting connected to your doctor. Incredible, how
                          everything in my journey is too organised.
                        </p>
                        <div className="star">
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                          <i className="bi bi-star-fill as"></i>
                        </div>
                        <p className="nm"></p>
                      </div>

                      <div className="small-square">
                        <img src="/customer2.jpg" className="img-fluid" />
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div className="col-md-4 order-mobile">
                  <img src="/customerreview.gif" className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-while">
        <div className="container py-5">
          <div className="">
            <div className="row">
              <h3 className="hero-title  text-center pb-5">
                Everyone has a tab open to <span>myprega.com</span>
              </h3>
              <div className="col-12 col-lg-4 text-center">
                <div className="element">
                  <div className="top-border"></div>
                  <div className="d-flex flex-column content">
                    <div className="outerwrapper d-flex justify-content-center align-items-center">
                      <div className="sq">
                        <CounterUpComponent targetCount={162} duration={3000} />
                        <i className="bi bi-plus-lg"></i>{" "}
                      </div>
                    </div>
                    <h5 className="fw-bolder text-capitalize">
                      Medical experts
                    </h5>
                  </div>
                  <div className="bottom-border"></div>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center">
                <div className="element">
                  <div className="top-border"></div>
                  <div className="d-flex flex-column content">
                    <div className="outerwrapper d-flex justify-content-center align-items-center">
                      <div className="sq">
                        <CounterUpComponent targetCount={606} duration={3000} />
                        <i className="bi bi-plus-lg "></i>{" "}
                      </div>
                    </div>
                    <h5 className="fw-bolder text-capitalize">mom</h5>
                  </div>
                  <div className="bottom-border"></div>
                </div>
              </div>
              <div className="col-12 mx-auto col-lg-4 text-center ">
                <div className="element">
                  <div className="top-border"></div>
                  <div className="d-flex flex-column content">
                    <div className="outerwrapper d-flex justify-content-center align-items-center">
                      <div className="sq">
                        <CounterUpComponent
                          targetCount={2030}
                          duration={3000}
                        />
                        <i className="bi bi-plus-lg "></i>{" "}
                      </div>
                    </div>
                    <h5 className="fw-bolder text-capitalize">mom buddies</h5>
                  </div>
                  <div className="bottom-border"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light">
        <div className="container">
          <div className="mt-5">
            <div className="py-5">
              <div className="row align-items-center my-5">
                <div className="col-12 col-md-5">
                  <h3 className="hero-title" data-aos="zoom-in-down">
                    Are you someone, not a mom-to-be?
                  </h3>
                  <h3
                    className="hero-title"
                    style={{ fontWeight: "normal", color: "#a4113a" }}
                    data-aos="zoom-in-down"
                  >
                    Be mom buddy! Join Our Community!
                  </h3>

                  <div className="col-12 col-md-7 d-md-none">
                    <img src="/joinus.png" className="img-fluid" />
                  </div>

                  <Link
                    className="mLEFT buy mt-2 mt-md-0"
                    style={{ cursor: "pointer", color: "white" }}
                    onClick={sendmail}
                  >
                    SUBSCRIBE
                  </Link>
                </div>
                <div
                  className="col-12 col-md-7 d-none d-md-block"
                  data-aos="zoom-in-down"
                >
                  <img src="/joinus.png" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Successstories;
