import React from "react";

const Sectionfour = () => {
  return (
    <div className="bg-light">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-12">
            <h2 className="hero-title text-center">
              You may be wondering why you haven’t heard about us before.
            </h2>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="left-image">
              <img src="/section4.gif" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="content">
              <div>
                <p className="under-subtitle text-bold py-2">
                  It’s because we were busy revolutionizing pregnancy care for
                  you!
                </p>
                <p className="under-subtitle text-bold py-2">
                  Now that we have perfected our technologically advanced and
                  completely transparent solutions, we are ready to take on the
                  world. Join us to challenge the outdated pregnancy care
                  methods and let’s cultivate a positive perspective towards
                  pregnancy and prenatal care together.
                </p>
                <p className="under-subtitle text-bold py-2">
                  While other service providers focus on one or two aspects of
                  pregnancy care, My Prega is raising the bar with comprehensive
                  pregnancy care under one roof.
                </p>
              </div>
            </div>
            <div className="content">
              <h4
                className="headline2"
                style={{
                  fontSize: "40px",
                  fontWeight: "",
                  paddingTop: "50px",
                  paddingBottom: "30px",
                  fontFamily: "Righteous",
                }}
              >
                There’s more.{" "}
                <span style={{ color: "#dc3a7d" }}>
                  Explore our packages now!
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sectionfour;
