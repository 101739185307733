import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import InteraktScript from "../../common/InteraktScript";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [userid, setUserid] = useState();
  useEffect(() => {
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
  }, [userid]);
  return (
    <div className="new-home">
      <Header userid={userid} />
      <div className=" margin-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 ">
              <h1 className="hero-title text-red">
                Behind the brand: Unveiling the heart of myprega
              </h1>
              <p className="bold-font">
                In Indian pregnancy care, neither pricing is standardized, nor
                is mom’s management, nor is the experience. There is no daily
                engagement between doctors and moms during care, lack of proper
                medical and non medical knowledge about pregnancy and lack of
                daily pregnancy health tracking lead to deprivation of required
                pregnancy care, leading to mishaps. We didn't really think that
                this was fair or right, and we wanted to change things with the
                use of technology. So we introduced myprega's comprehensive
                9-month pregnancy care solution to make your journey to
                motherhood smoother than ever, with the best experience among
                others.
              </p>
              <p className="bold-font mt-3">
                Looking for the perfect pregnancy care package? Look no further
                than myprega!
              </p>

              <p className="bold-font  mt-3">
                Ready to elevate your pregnancy experience? Dive into the world
                of myprega difference and discover how we're redefining
                antenatal care for expecting moms everywhere using technology!
                From personalized support to medical expert guidance, we’ve got everything covered 
                in one affordable tech-driven package. We are your ultimate partner for
                a healthy and stress-free pregnancy. Elevate Your Pregnancy
                Journey with myprega's all-in-one comprehensive tech-driven 9-months pregnancy 
                care package
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/about.png" className="w-100" />
            </div>
          </div>
          <div className="container my-5">
            <div className="row">
              <div className="col-12 col-md-6 mb-5 mb-md-1">
                <div className="oval position-relative">
                  <p
                    className="px-1 pt-5 px-lg-3 fw-bolder text-center"
                    id="fonts"
                    style={{ paddingBottom: "100px" }}
                  >
                    <h2
                      className="fw-bold"
                      style={{
                        fontSize: "35px",
                        fontWeight: "bolder",
                        paddingBottom: "30px",
                      }}
                    >
                      Vision
                    </h2>
                    Reimagine & cultivate perspective of people towards
                    pregnancy and evolve the way pregnancy care have been
                    happening for decades.
                  </p>
                  <div className="circle position-absolute">
                    <img src="/vision.png" className="vision" />
                  </div>
                </div>
              </div>
              <div className="col-6 d-none d-md-block"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-6 d-none d-md-block"></div>
              <div
                className="col-12 col-md-6"
                style={{ position: "relative", top: "-70px" }}
              >
                <div className="oval position-relative">
                  <p
                    className="px-1 fw-bolder pt-2 text-center"
                    id="fonts"
                    style={{ paddingBottom: "80px" }}
                  >
                    <h2
                      className="fw-bold"
                      style={{
                        fontSize: "35px",
                        fontWeight: "bolder",
                        paddingBottom: "30px",
                      }}
                    >
                      Mission
                    </h2>
                    Organising the unorganised pregnancy care by integrating it
                    with technology
                  </p>
                  <div className="circle position-absolute">
                    <img src="/mission.png" className="vision" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <InteraktScript />
    </div>
  );
}

export default About;
