import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

const Pricingdetails = ({
  isAtTop,
  pricingparacontent,
  imgrad,
  pricecherishcare,
  headerRef,
  packageDetails,
  subbtn,
  handlePayment,
  sharebtn,
  handleShare,
  democlass,
  month,
  monthno,
  monthhead,
  selectedValue, // Selected Area ID
}) => {
  const [doctorList, setDoctorList] = useState({});
  const [expanded, setExpanded] = useState({}); // toggle the visibility of doctors under each category
  const hasFetched = useRef(false); // Prevents duplicate API calls

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to toggle the visibility of doctors under each category
  const toggleExpand = (expertName) => {
    setExpanded((prev) => ({
      ...prev,
      [expertName]: !prev[expertName],
    }));
  };
  // Function to toggle the visibility of doctors under each category (ENDS)

  // Fetch doctor data when selectedValue changes
  useEffect(() => {
    const fetchDoctorList = async () => {
      if (!selectedValue || hasFetched.current) return; // Prevent duplicate fetch
      hasFetched.current = true; // Mark as fetched

      try {
        console.log("Fetching doctor list for area_id:", selectedValue);

        const response = await axios.post(
          "https://admin.myprega.com/api/v1/common/get_doctor_list_by_id",

          { area_id: Number(selectedValue) } // Ensure correct type and Dynamically fetch area ID from (selectedValue)
        );

        console.log("Full API Response:", response.data);

        if (response.data.success) {
          console.log("Doctor List Data:", response.data.Doctor_list);

          // If no doctors found, return early
          if (
            !response.data.Doctor_list ||
            response.data.Doctor_list.length === 0
          ) {
            console.warn("No doctors found in response.");
            return;
          }

          // Group doctors by medical_expert_name
          const groupedDoctors = response.data.Doctor_list.reduce(
            (acc, doctor) => {
              if (!acc[doctor.medical_expert_name]) {
                acc[doctor.medical_expert_name] = [];
              }
              acc[doctor.medical_expert_name].push({
                user_name: doctor.user_name,
                education: doctor.education_name,
                experience: doctor.experience,
              });
              return acc;
            },
            {}
          );

          // Ensure "Meditation Expert" has the same doctors as "Yoga Expert"
          if (groupedDoctors["Yoga Expert"]) {
            groupedDoctors["Meditation Expert"] = groupedDoctors["Yoga Expert"];
          }

          console.log("Grouped Doctors:", groupedDoctors);
          setDoctorList(groupedDoctors);
        } else {
          console.error("API returned an error:", response.data);
        }
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    };

    fetchDoctorList();
  }, [selectedValue]);

  return (
    <>
      <div className="">
        <div className="container p mt-5">
          <div
            id="myHeader"
            style={{
              backgroundColor: isAtTop ? "white" : "white",
              width: "90%",
              margin: "0px auto",
              // position: "sticky",
              // top: "50px",
              // zIndex: "9999",
            }}
          >
            <div
              className="container pt-5 p-0"
              style={{
                boxShadow: "0px 16px 32px 0px rgba(233, 238, 242, 0.4)",
              }}
            >
              <div className="row align-items-center p-3">
                <div className="col-md-5">
                  <img src="/pricingimage.gif" className="w-100" alt="" />
                </div>
                <div className="col-md-7 ">
                  <h1 className="hero-title text-red">Cherish Care</h1>
                  <p className="bold-font">
                    Finding the right pregnancy care that is not heavy on your
                    pocket is more like a task. To reduce your burden we help
                    anytime anyday with the best all inclusive pregnancy care at
                    an affordable rate.
                  </p>
                </div>
              </div>
              <hr className="w-100 my-3"></hr>
              <div
                className="p-0"
                ref={headerRef}
                style={{
                  position: !isAtTop ? "fixed" : "",
                  top: !isAtTop && "90px",
                  zIndex: "103",

                  width: !isAtTop && "83%",
                  // paddingBottom:!isAtTop && "30px",
                  boxShadow: "0px 16px 32px 0px rgba(233, 238, 242, 0.4)",
                }}
              >
                <div className="container-fluid bg-white">
                  <div className="row py-3 align-items-center">
                    <h1
                      className="py-4 ps-5"
                      style={{
                        display: isAtTop ? "none" : "block",
                        color: "#3C4852",
                        fontWeight: "bold",
                        fontSize: "24px",
                        lineHeight: "120%",
                      }}
                    >
                      Cherish Care
                    </h1>
                    {!isAtTop && (
                      <hr
                        className="mx-auto py-4"
                        style={{ width: "90%" }}
                      ></hr>
                    )}
                    <div className="col-12 col-md-6 text-left fs-1 p-0">
                      <div className="d-flex ps-5 align-items-center">
                        <div>
                          <i class="bi bi-currency-rupee"></i>
                        </div>
                        <div>
                          <h2>{packageDetails?.pricing}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 text-left text-md-right">
                      {/* <button className="me-3" >Buy Now</button> */}
                      <button
                        href="#"
                        style={{ fontSize: "23px", ...subbtn }}
                        // style={{subbtn, fontSize: "25px" }}
                        className="me-3 text-white"
                        onClick={handlePayment}
                      >
                        BUY NOW
                      </button>
                      <button style={sharebtn} onClick={handleShare}>
                        <i class="bi bi-share"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h6 className="hero-title text-red">About</h6>
              <p className="bold-font">
                Complete 10 month pregnancy care package from day 1 of your
                pregnancy till delivery of your baby
              </p>
            </div>
            <div className="col-md-8">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i1.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">10 Medical Specialists</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i2.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        44 In-Clinic & Online Consultations
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i3.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        40 Pathology Blood & Genetics lab test
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i5babyind.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        09 Ultrtasound (Sonography) Scans
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i1.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">117 Yoga Sessions</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i1.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">18 Meditation Sessions</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i6babycap.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        Garbhasanskar Acess for 9 Month
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i7watch.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        Daily Pregnancy Health Tracking
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i824x7.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">24/7 Emergency</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i1.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">Education on Pregnancy</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i9pregnantlady.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">Pregnancy Care Kit</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i10gift.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        Monthly Premium Exclusive Giveaway Basket
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i11.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        Complete Transparency Over Care Through Dashboard
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Doctor_list */}
        <div className="container py-5">
          <div className="row">
            <div className="col-md-4">
              <h6 className="hero-title text-red">Medical Specialist</h6>
            </div>
            <div className="col-md-8">
              <div className="container">
                <div className="row">
                  {[
                    "Gynecologist",
                    "General Physician",
                    "Pediatrician",
                    "Yoga Expert",
                    "Dietitian",
                    "Clinical Psychologist",
                    "Breastfeeding Consultant",

                    "Meditation Expert",
                    // "Diagnostics Lab Center",
                    "Sonography Centre",
                    "Pathology Lab",
                  ].map((expertName, index) => (
                    <div key={index} className="col-12 col-md-6 my-1">
                      <div
                        className="d-flex mx-2 bg-white ps-4 py-4 align-items-start"
                        onClick={() => toggleExpand(expertName)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="ps-3 w-100">
                          {/* Medical Expert Name with Arrow */}
                          <div
                            className="text-secondary"
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "18px",
                            }}
                          >
                            {expanded[expertName] ? "▼" : "▶"} {expertName}
                          </div>

                          {/* Doctor Details (Show only when expanded) */}
                          {expanded[expertName] &&
                            (doctorList[expertName]?.length > 0 ? (
                              <ul className="list-unstyled mt-2">
                                {doctorList[expertName].map((doctor, idx) => (
                                  <li key={idx} className="mb-2">
                                    <strong>👨‍⚕️ {doctor.user_name}</strong>{" "}
                                    <br />
                                    🎓 <span>{doctor.education}</span> <br />⏳{" "}
                                    <span>
                                      {doctor.experience} years experience
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              // <p className="text-muted mt-2">No doctor available</p>
                              <p className="text-muted mt-2">
                                {expertName === "Meditation Expert"
                                  ? "Meditation services coming soon!"
                                  : // : expertName === "Diagnostics Lab Center"
                                  // ? "New Diagnostics lab services coming soon!"
                                  expertName === "Sonography Centre"
                                  ? "Sonography services will be available soon!"
                                  : expertName === "Pathology Lab"
                                  ? "New Pathology Lab services coming soon!"
                                  : "Doctors will be listed soon!"}
                              </p>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Debugging Output */}
                {/* <div className="mt-4">
              <h3>Selected Area ID: {selectedValue ? selectedValue : "Not Selected"}</h3>
              <h3>Doctor List Debug</h3>
              <pre>{JSON.stringify(doctorList, null, 2)}</pre>
            </div>  */}
              </div>
            </div>
          </div>
        </div>

        {/* Doctor_list */}
        <div className="container py-5">
          <div className="row">
            <div className="col-md-4">
              <h6 className="hero-title text-red">
                Schedule
                <br /> Daily{" "}
              </h6>
              <p className="font-bold"></p>
            </div>
            <div className=" col-md-8">
              <div className="container">
                <div className="row">
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            1
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            7 Diagnostic lab tests, 1 Sonography, 1 Gynecologist
                            consultation, 1 General Physician consultation, 1
                            Dietitian consultation, 1 Psychiatrist consultation,
                            13 Yoga sessions, and 2 Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            2
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Gynecologist consultation, 1 Psychiatrist
                            consultation, 13 Yoga sessions, and 2 Meditation
                            sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            3
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Gynecologist consultation, 1 General Physician
                            consultation, 1 Dietitian consultation, 1
                            Psychiatrist consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            4
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            5 Diagnostic lab tests, 2 Sonography, 2 Gynecologist
                            consultation, 2 General Physician consultation, 1
                            Psychiatrist consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            5
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Sonography, 1 Gynecologist consultation, 1
                            Dietitian consultation, 1 Psychiatrist consultation,
                            13 Yoga sessions, and 2 Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            6
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Sonography, 1 Gynecologist consultation, 1
                            Psychiatrist consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            7
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            4 Diagnostic lab tests, 1 Sonography, 2 Gynecologist
                            consultation, 2 General Physician consultation, 1
                            Dietitian consultation, 1 Psychiatrist consultation,
                            1 Pediatrician consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            8
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            2 Gynecologist consultation, 1 General Physician
                            consultation, 1 Psychiatrist consultation, 13 Yoga
                            sessions, and 2 Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            9
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Diagnostic lab tests, 2 Sonography, 3 Gynecologist
                            consultation, 3 General Physician consultation, 1
                            Dietitian consultation, 1 Psychiatrist consultation,
                            1 Pediatrician consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center mb-5">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            10
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Sonography, 1 Gynecologist consultation, 1 General
                            Physician consultation
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricingdetails;
