import React, { useState, useEffect } from "react";

const PopupHome = () => {
  const [isPopupHomeVisible, setPopupHomeVisible] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
      const timer = setTimeout(() => {
        setPopupHomeVisible(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  const closePopupHome = () => {
    setPopupHomeVisible(false);
  };

  return (
    <>
      {isPopupHomeVisible && (
        <div
          className="popuphome-overlay"
          onClick={closePopupHome}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            marginTop: window.innerWidth > 768 ? "0px" : "28px",
          }}
        >
          <div
            className="popuphome"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: "16px",
              padding: "30px",
              maxWidth: "900px",
              textAlign: "center",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              maxHeight: "90vh",
              maxHeight: "80vh",
              overflow: "auto",
              // Apply scrollbar styling only for mobile
              ...(window.innerWidth <= 768 && {
                scrollbarWidth: "auto",
                scrollbarColor: "#e91e63 #f1f1f1",
              }),
            }}
          >
          
            {/* Close button */}
            <button
              onClick={closePopupHome}
              style={{
                position: "absolute",
                top: "5px",
                right: "15px",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "38px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#333",
              }}
              aria-label="Close popup"
            >
              &times;
            </button>

            <h2
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#e91e63",
                marginBottom: "15px",
              }}
            >
              💬Have a Pregnancy Related Concern? Get Instant Doctor’s Advice –
              100% Free! 🎉
            </h2>

            <p
              style={{
                fontSize: "18px",
                color: "#555",
                marginBottom: "15px",
                fontWeight: "bolder",
              }}
            >
              🤰 Worried about something in your pregnancy? Confused about a
              symptom? Need expert advice ASAP?
            </p>

            <p
              style={{ fontSize: "16px", color: "#333", marginBottom: "10px" }}
            >
              Pregnancy can be overwhelming, and we believe every mom-to-be
              deserves medically accurate answers, instantly and hassle-free.
              That’s why we’re offering you direct access to our expert
              gynecologists on WhatsApp completely free – no sign-ups, no hidden
              costs, no sales pitch. Get a doctor-verified solution instantly.
            </p>
            <p
              style={{ fontSize: "16px", color: "#333", marginBottom: "15px" }}
            >
              💬 Simply ask your pregnancy-related question, and our practicing
              medical experts (Doctors) will provide a instant, personalized,
              medically verified solution – completely free of charge.
            </p>

            <p
              style={{ fontSize: "16px", color: "#333", marginBottom: "15px" }}
            >
              At Myprega, we genuinely care for every mom-to-be in India. You
              are a part of the Myprega family, and At Myprega, moms always come
              first—before business, before profits. We started Myprega to make
              a real impact, change pregnancy care in India, and ensure every
              mother gets the right guidance at the right time
            </p>

            <p
              style={{
                fontSize: "18px",
                color: "#555",
                marginBottom: "15px",
                fontWeight: "bolder",
              }}
            >
              ✨Because your health and peace of mind are your rights—not a
              luxury.
            </p>
            <button
              onClick={() => {
                window.open("https://wa.me/919702324628", "_blank");
              }}
              style={{
                backgroundColor: "#e91e63",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Ask Our Doctor Now - Free!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupHome;
