import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Serviceprovider = () => {
  return (
    <>
      <div className="bg-white">
        <div className="container py-4 ">
          <div className="row">
            <div className="col-md-12">
              <h2 className="hero-title text-center">
                {" "}
                {/* How are we different from other service providers? */}
                Not Just Different—Better. Here’s Why Moms Choose Myprega over others
              </h2>
            </div>
          </div>
          <div className="row">
          <div className="horizontal-trigger col-md-6">
              <img
                src="/section3.gif"
                style={{
                  width: "100%",
                  position: "sticky",
                  top: "150px",
                }}
                fetchpriority="high"
              ></img>
            </div>
            <div className="vertical-trigger col-md-6">
              <div>
                <div className="cards">
                All-in-One Complete Tech-Driven Pregnancy Care Package. 
                We’ve got everything covered in one package.
                </div>
                <div className="cards">
                We put your entire 9-month journey in your hands through our India's first tech-driven pregnancy management model.
                </div>
                <div className="cards">
                What sets us apart is daily engagement with all your doctors, who monitor your progress daily & answer your queries. 
                We’ve reimagined the doctor-mom relationship by integrating daily engagement in our model. 
                </div>
                <div className="cards">
                Daily pregnancy health tracking powered by AI, ensuring early identification of risks. 
                </div>
                <div className="cards">
                Your team of 9 medical experts with different specialties is chosen after a thorough screening process.
                </div>
                <div className="cards">
                  {" "}
                  Care at your convenience at an affordable price without compromising on quality in a standardized & safe manner.
                </div>
                <div className="cards">
                Prevention-first model with focus on mental health.
                </div>
                <div className="cards">
                At-home lab tests, Personalized diet plans and live yoga classes.
                </div>
                <div className="cards">You will receive a detailed description of every penny you spend. </div>
                <div className="cards">
                We ensure complete transparency and visibility throughout your care at your fingertips.
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Serviceprovider;
