import React, { useEffect, useState } from "react";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios"; // Import axios
import { webUrl, webUrlGarbhsanskar } from "../Utility/config";
import configData from "../../config.json";

const Garbhasanskar = ({ user }) => {
  const userid = localStorage.getItem("userId");
  const [patientdata, setPatientdata] = useState([]);
  const [weekwisedata, setWeekwisedata] = useState([]);
  const [babywt, setBabywt] = useState(null);
  const [daywisedata, setDaywisedata] = useState([]);
  const [ht, setHeight] = useState(null);
  const navigate = useNavigate();
  const [length, setLength] = useState(null);
  const [weight, setWeight] = useState(null);
  const [kg, setKg] = useState(null);
  const [cm, setCm] = useState(null);

  const gettinggarbhsanskardata = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_garbhsanskar_data`,
        {
          patientId: userid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        const weekData = response.data?.weekwiseData[0];
        setWeekwisedata(weekData);
        setDaywisedata(weekData?.dayWiseData[0]);

        const height = weekData?.length;
        setHeight(height);
        if (height) {
          const [len, unit] = height.split(" ");
          setLength(len);
          setCm(unit);
        }

        const weightbaby = weekData?.weight;
        setBabywt(weightbaby);
        if (weightbaby) {
          const parts = weightbaby.split(" ");
          if (parts.length > 2) {
            setWeight(parts.slice(0, 3).join(" "));
            setKg(parts[3]);
          } else {
            setWeight(parts[0]);
            setKg(parts[1]);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getsinglepatientdetail = async (id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_single_user`,
        {
          userId: id,
        }
      );

      if (response.data.success) {
        setPatientdata(response.data.user);
        if (response.data.user?.user_role_master_id == 1) {
          gettinggarbhsanskardata(userid);
        }
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  useEffect(() => {
    if (userid) {
      getsinglepatientdetail(userid);
    }
  }, [userid]);

  const openapp = () => {
    if (patientdata && typeof patientdata.payment_status !== "undefined") {
      if (patientdata.payment_status === true) {
        window.location.href = `${webUrl}`;
      } else {
        window.location.href = `${webUrlGarbhsanskar}`;
      }
    } else {
      getsinglepatientdetail(userid);
      openapp();
    }
  };

  return (
    <div>
      {/* Lead magnet box */}
      <div>
  <div className="container py-5">
    <div className="drawborder">
      <div className="row align-items-center">
        {/* Left Side - Image */}
        <div className="col-md-6">
          <div className="left-image">
            <img
              src="/GetYourFree.gif"
              alt="Pregnancy Guide"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>

        {/* Right Side - Content */}
        <div className="col-md-6 text-center">
          <h2 style={{ color: "#004D40", 
            fontWeight: "bold",
            fontSize: window.innerWidth > 768 ? "32px" : "16px",
            paddingBottom: window.innerWidth > 768 ? "28px" : "5px", }}>
            Get Your Free <span style={{ color: "red" }}>Personalized Pregnancy Journey Planner!</span>
          </h2>
          <p style={{ color: "#555",
                      fontSize: window.innerWidth > 768 ? "20px" : "16px",
                      lineHeight: window.innerWidth > 768 ? "": "1.6",
                      paddingBottom: window.innerWidth > 768 ? "20px" : "5px", }}>
            Wondering what your perfect pregnancy care should look like? Answer a few quick questions,
            and our experts will create a detailed, personalized pregnancy journey planner just for you—for 
            <strong> FREE! 💖</strong>
          </p>
          <p style={{ color: "#555", 
            fontSize: window.innerWidth > 768 ? "20px" : "16px", 
            lineHeight: "1.6",
            paddingBottom: window.innerWidth > 768 ? "28px" : "5px", }}>
            Your unique pregnancy journey planner will include everything from <strong>health tips, scans, and 
            consultations</strong> to <strong>mental wellness and nutrition</strong>, tailored to YOUR needs.
          </p>
          <p style={{ fontSize: window.innerWidth > 768 ? "22px" : "18px", 
            fontWeight: "bold", 
            color: "#333",
            paddingBottom: window.innerWidth > 768 ? "28px" : "15px", }}>
            ✨ Start now and take the first step toward a smooth, worry-free pregnancy journey!
          </p>
          <button
              onClick={() => {
                window.location.href = "/form"; // Direct navigation
              }}
              style={{
                backgroundColor: "#e91e63",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                padding: window.innerWidth > 768 ? "15px 30px" : "10px 20px",
                fontSize: window.innerWidth > 768 ? "18px" : "16px",
                cursor: "pointer",
                marginBottom: window.innerWidth > 768 ? "0px" : "15px",
              }}
            >
              Get My Free Planner!
            </button>
        </div>
      </div>
    </div>
  </div>
</div>

      
    {/* Lead magnet box */}
      
      <div className="container py-5">
        <div className="drawborder">
          <div className="p-3">
            <div
              className="row align-items-center mx-2"
              style={{ backgroundColor: "#EDFFFE" }}
            >
              <div className="col-12 col-md-5">
                <div className="innerdiv1">
                  <div className="one position-relative mb-4 px-3">
                    <h6 className="fw-bolder mb-0">
                      Weight of your baby today is:
                    </h6>
                    {user !== "0" && user !== null && user !== "null" ? (
                      <p></p>
                    ) : (
                      <div className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center">
                        <i className="bi bi-lock-fill ll"></i>
                      </div>
                    )}
                    {user && babywt ? (
                      <div className="ps-4">
                        {weight} {kg}
                      </div>
                    ) : (
                      <span className="ps-4">
                        <HorizontalRuleIcon />
                      </span>
                    )}
                  </div>

                  <div className="two position-relative my-4 px-3">
                    <h6 className="fw-bolder mb-0">
                      Height of your baby today is:
                    </h6>
                    {user !== "0" && user !== null && user !== "null" ? (
                      <p></p>
                    ) : (
                      <div className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center">
                        <i className="bi bi-lock-fill ll"></i>
                      </div>
                    )}
                    {user && ht ? (
                      <div className="ps-4">
                        {length} {cm}
                      </div>
                    ) : (
                      <span className="ps-4">
                        <HorizontalRuleIcon />
                      </span>
                    )}
                  </div>

                  <div className="three position-relative mt-4 px-3">
                    <h6 className="fw-bolder mb-0">Hormone info:</h6>
                    {user && weekwisedata?.Hormonal_information ? (
                      <div className="ps-4">
                        {weekwisedata?.Hormonal_information}
                      </div>
                    ) : (
                      <span className="ps-4">
                        <HorizontalRuleIcon />
                      </span>
                    )}
                    {user !== "0" && user !== null && user !== "null" ? (
                      <p></p>
                    ) : (
                      <div className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center">
                        <i className="bi bi-lock-fill ll"></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3 text-center">
                <div className="innerdiv2 position-relative">
                  {/* Baby Face Image */}
                  {user && weekwisedata?.shape_image ? (
                    <img
                      className="mb-2 img-fluid mx-auto"
                      src={`https://admin.myprega.com/baby-shape/${weekwisedata.shape_image}`}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                      }}
                      alt="Baby Shape"
                    />
                  ) : (
                    <h6 className="fw-bolder">Baby Shape</h6>
                  )}
                  {user !== "0" && user !== null && user !== "null" ? (
                    <p></p>
                  ) : (
                    <div className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center">
                      <i className="bi bi-lock-fill ll"></i>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "500",
                    marginTop: "30px",
                  }}
                >
                  {weekwisedata?.baby_shap_name
                    ? `Your Baby is Size of a ${weekwisedata.baby_shap_name}`
                    : "Baby Shape"}
                </div>
              </div>

              <div className="col-12 col-md-4 pt-5 pb-4 pt-lg-0">
                <div className="innerdiv3 mt-lg-0 p-4 position-relative">
                  {user && daywisedata?.description ? (
                    <span>{daywisedata.description}</span>
                  ) : (
                    <h6 className="fw-bolder">Fact of the day</h6>
                  )}
                  {user !== "0" && user !== null && user !== "null" ? (
                    <p></p>
                  ) : (
                    <div className="position-absolute h-100 w-100 lockdiv d-flex justify-content-center align-items-center">
                      <i className="bi bi-lock-fill ll"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {(!user || user === "0") && (
              <div className="row py-3 mx-auto">
                <div className="text-center">
                  <button
                    className="buttongrid"
                    onClick={() => navigate("/patient/loginform")}
                  >
                    Log In
                  </button>
                  <button
                    className="buttongrid ml-4"
                    onClick={() => navigate("/patient/Signup")}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            )}

            {user && (
              <div className="text-center my-4">
                <img
                  src="/dashboardimage.png"
                  className="imgdashboard"
                  alt="Dashboard"
                />
                <br />
                <Button
                  onClick={openapp}
                  className="mt-2"
                  style={{ backgroundColor: "#0000ff", fontWeight: "bolder" }}
                >
                  Go to Dashboard
                </Button>
              </div>
            )}

            <h6 className="fw-bolder text-center">
              For making your pregnancy personalized and awesome!
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Garbhasanskar;
