import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { webUrlLogin } from "../Utility/config";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import configData from "../../config.json";
import "../../Pricing.css";
import InteraktScript from "../../common/InteraktScript";
import Pricingmain from "../../common/Pricing/Pricingmain";
import Pricingdetails from "../../common/Pricing/Pricingdetails";
let paymentid;
let orderid;
let razorpaysignature1;
function Pricing() {
  const pricingparacontent= {
    color: "#7A8B94",
    fontSize: "14px",
    lineHeight: "150%"
  }
const pricecherishcare=
  {
    color: "#3C4852",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "120%",  
  }
  const imgrad={
    borderRadius:"10px",
  }
  const headerRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const [areas, setAreas] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [areaData, setAreaData] = useState(false);
  const [packageDetails, setPackageDetails] = useState();
  const [nextpg,setNextpg]=useState(false);
  const [pricing, setPricing] = useState("");
  const [patientdata, setPatientdata] = useState("");
  const [paymentId, setPaymentId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [razorpaysignature, setRazorpaySignature] = useState(null);
  const [areaprice, setAreaprice] = useState(0);
  const [userid, setUserid] = useState(0);
  const [Razorpay] = useRazorpay();
  const naviagte = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    getAreas();
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
    getsingleuserdetail();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
                // Timeline
                window.addEventListener('scroll', () => {
                  const timeline = document.querySelector('.timeline-line');
                  const docHeight = document.documentElement.scrollHeight - window.innerHeight;
                  const scrollTop = window.scrollY;
                  const scrollPercent = (scrollTop / docHeight) * 100;
                
                  if (timeline) {
                    timeline.style.height = `${scrollPercent}%`;
                  }
                });
                
                //  Timeliinr
  const handleScroll = () => {
    setIsAtTop(window.scrollY === 0);
  };

  const getsingleuserdetail = async () => {
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/users/get_single_user`, {
        userId: localStorage.getItem("userId"),
      });

      if (response.data.success) {
        setPatientdata(response.data.user);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const calling = async () => {
    const pr = areaprice * 100;
    try {
      const response = await axios.post(`${configData.SERVER_API_URL}/checkout/getorderId`, {
        amount: pr,
        currency: "INR",
      });
      const data = response.data;
      return data;
    } catch {
      console.log("Error");
    }
  };
  const handlePayment = useCallback(async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      if (userId != 0) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: userId,
          }
        );
        if (response.data.success) {
          const user = response.data.user;

          const order = await calling();
          console.log("order", order);
          const options = {
            key: "rzp_live_5FkwM0dSiSrFis",
            key_secret: "YZPhYx4f6silMc8BHobIudxD",
            amount: areaprice * 100,
            currency: "INR",
            name: "Myprega",
            description: "Test Transaction",
            image: "https://myprega.com/logo.png",
            order_id: order?.order_details.id,
            handler: (res) => {
              paymentid = res.razorpay_payment_id;
              orderid = res.razorpay_order_id;
              razorpaysignature1 = res.razorpay_signature;

              setPaymentId(paymentid);
              setOrderId(orderid);
              setRazorpaySignature(razorpaysignature);

              console.log("Payment ID:", paymentid, paymentId);
              console.log("Order ID:", orderid, orderId);
              console.log(
                "Razorpay Signature:",
                razorpaysignature1,
                razorpaysignature
              );

              paymentdetail(paymentid, orderid, razorpaysignature1);
            },
            prefill: {
              name: user.fname + " " + user.lname, //user deytails
              email: user.email,
              contact: user.phone,
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };

          const rzpay = new Razorpay(options);
          rzpay.open();
        }
      } else {
        alert("Please SignUp");
      }
    } else {
      naviagte("/patient/loginform");
    }
  }, [areaprice, Razorpay]);

  const options = {
    key: "rzp_test_mel87ldPGvyLYj", // Replace with your Razorpay Key ID
    amount: 100, // Amount in paise
    currency: "INR",
    name: "Acme Corp",
    description: "Test Transaction",
    image: "https://example.com/your_logo",
    order_id: "order_MyNFHL1FQ6cjgh", // Replace with your Order ID
    callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
    prefill: {
      name: "Gaurav Kumar",
      email: "gaurav.kumar@example.com",
      contact: "9000090000",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };
  const paymentdetail = async (paymentid, orderid, razorpaysignature1) => {
    try {
      const userId = localStorage.getItem("userId");
      const p = areaprice;

      const response = await axios.post(
        `${configData.SERVER_API_URL}/checkout/store_payment_details`,
        {
          user_id: userId,
          razorpay_payment_id: paymentid,
          razorpay_order_id: orderid,
          payment_status: "SUCCESS",
          razorpay_signature: razorpaysignature1,
          amount: p,
        }
      );
      if (response.data.success) {
        console.log("sent detail successfully");
        getsingleuserdetail();
        if (patientdata.payment_status == true) {
          window.location.href = `${webUrlLogin}`;
        }
      }
    } catch {
      console.log("error");
    }
  };

  const getAreas = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/users/get_areas_list`,
        {}
      );
      if (response.data.success) {
        setAreas(response.data?.list);
      }
    } catch (error) {
      console.error("Error get areas:", error);
    }
  };

  const handleChangeGetDetails = async (e) => {
    const areaId = e.target.value;
    let currentarea = areas.find((item) => item.id == areaId)?.pricing;
    setAreaprice(currentarea);
    setSelectedValue(areaId);

    if (areaId) {
      try {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/common/getAreaDetailsById`,
          {
            area_id: areaId,
          }
        );

        if (response.data.success) {
          setPackageDetails(response.data.Area_Details[0]);
          const p = response.data.Area_Details[0].pricing;
          setPricing(p);
          setAreaData(true);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    } else {
      setAreaData(false);
    }
  };
  const proceedtonext=()=>{
    if(selectedValue!="")
    setNextpg(true);
  else
  alert("Please Select Area first");
  }
  const handleShare = () => {
    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: "Myprega",
          text: "Check out this link!",
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // Fallback if Web Share API is not supported
      alert("Web Share API is not supported in your browser.");
      // You can provide a fallback mechanism here, such as copying the link to the clipboard
    }
  };

 
const subbtn={
backgroundColor:"#3C4852",
color:"white",
width:"200px",
borderRadius:"12px",  
height:"55px",
marginRight:"20px",
}
const sharebtn={
  backgroundColor:"white",
  border:"1px solid gray",
  height:"55px",
  borderRadius:"12px",
  width:"60px",
}
const yellowclr={
  height:"20px",
  width:"20px",
  backgroundColor:"yellow",
  borderRadius:"50%",
}
const democlass={
  width:"85%"
}
const demoimg = {
  borderRadius:"6px",
}
const elemental={
  border:"1px solid #F5F5F5",
  fontSize:"9px",
  borderRadius:"10px",
}
const month={
  color:"#3C4852",
  fontSize:"12px",
  fontWeight:"600",
}
const monthno={
  color:"#3C4852",
  fontSize:"16px",
  fontWeight:"bolder",
}
const monthhead={
  color:"#3C4852",
  fontSize:"12px",
  fontWeight:"600",
  lineHeight:"150%",
}
  return (
    <div className="new-home">
    <div style={{ backgroundColor: nextpg === true ? "#f5f5f5" : "white" }}>
      <Header userid={userid} style={{ zIndex: "1030" }} />

      {nextpg === true ? (
        <Pricingdetails
         selectedValue={selectedValue} // Pass selectedValue
        //  handleChangeGetDetails={handleChangeGetDetails}
         isAtTop={isAtTop}
         imgrad={imgrad}
         pricingparacontent={pricingparacontent}
         pricecherishcare={pricecherishcare}
         headerRef={headerRef}
         packageDetails={packageDetails}
         subbtn={subbtn}
         handlePayment={handlePayment}
         sharebtn={sharebtn}
         handleShare={handleShare}
         democlass={democlass}
         month={month}
         monthno={monthno}
         monthhead={monthhead}
        />

      ) : (
        <Pricingmain
          isAtTop={isAtTop}
          imgrad={imgrad}
          selectedValue={selectedValue}
          handleChangeGetDetails={handleChangeGetDetails}
          areas={areas}
          proceedtonext={proceedtonext}
        />
      )}

      <Footer />
      <InteraktScript />
    </div>
  </div>
  );
}

export default Pricing;
