import React from "react";
import { Link } from "react-router-dom";

const Packages = () => {
  return (
    <div className="bg-light">
      {" "}
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <h2 className="hero-title text-center">Our Tailored Pregnancy Packages</h2>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-borderless table-package">
                <thead>
                  <tr>
                    <th>
                      <div className="package-title-container">
                        <img src="/E1final.png" className="w-100" />
                        <h3> Elemental Care</h3>
                        <span style={{ color: "#a4113a" }}>
                          {" "}
                          Starting from<i className="bi bi-currency-rupee"></i>0
                        </span>
                        <Link
                          to={"/elementalcare"}
                          style={{
                            color: "blue",
                          }}
                        >
                          Explore More
                        </Link>
                        <Link className="buy" to={"/elementalcare"}>
                          EXPLORE
                        </Link>
                      </div>
                    </th>
                    <th>
                      <div className="package-title-container">
                        <img src="cherishfinal.png" className="w-100" />
                        <h3>Cherish Care</h3>
                        <span style={{ color: "#a4113a" }}>
                          {" "}
                          Starting from<i className="bi bi-currency-rupee"></i>
                          69,999
                        </span>
                        <Link
                          to={"/cherishcare"}
                          style={{
                            color: "blue",
                          }}
                        >
                          Explore More
                        </Link>
                        <Link className="buy" to={"/pricing"}>
                          BUY NOW
                        </Link>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="table-item">
                        <img
                          src="/si1babyholdwidhand.png"
                          className="icon"
                          alt="nt found"
                        />
                        <span>Completely free for 7 days</span>
                      </div>
                    </td>
                    <td>
                      <div className="table-item">
                        <img src="/i1.jpg" className="icon" />
                        <span> Team of 9 experienced medical experts <br />
                        from different specialties for each mom</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i2.jpg" className="icon" />
                        <span>
                          {" "}
                          44 Consultation sessions with the doctor, <br />
                          including both in-clinic & online
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i3.jpg" className="icon" />
                        <span>
                          {" "}
                          40 Pathology Blood & Genetics lab test
                          {/* <br />
                          and Genetic Tests */}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i4circle.jpg" className="icon" />
                        <span> Personalized diet, yoga, mental health & lactation sessions</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i5babyind.jpg" className="icon" />
                        <span> 9 Ultrtasound (Sonography) Scans</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="table-item">
                        <img src="/i6babycap.jpg" className="icon" />
                        <span>
                          Foetus Education (Garbh sanskar)
                          <br />
                          care for complete 7 days
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="table-item">
                        <img src="/i6babycap.jpg" className="icon" />
                        <span>
                        Daily foetus education sessions 
                          <br />
                          (Garbha Sanskar Sessions)
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i7watch.jpg" className="icon" />
                        <span>
                          {" "}
                          Daily Pregnancy Health Tracking &  <br />
                          Analysis via Health Tracker
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>                      
                      <div className="table-item">
                        <img src="/i824x7.jpg" className="icon" />
                        <span> 24x7 Emergency Support</span>
                      </div></td>
                    <td>
                      <div className="table-item">
                        <img src="/i824x7.jpg" className="icon" />
                        <span> 24x7 Emergency Support</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i9pregnantlady.jpg" className="icon" />
                        <span> Pregnancy Care Kit</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i10gift.jpg" className="icon" />
                        <span>
                          Monthly premium exclusive <br />
                          giveaway basket
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="table-item">
                        <img src="/i11.jpg" className="icon1" />
                        <span>End-to-end management of complete <br />
                        journey through our tech-driven model</span>
                      </div>
                    </td>
                    <td>
                      <div className="table-item">
                        <img src="/i11.jpg" className="icon1" />
                        <span>End-to-end management of complete <br />
                        journey through our tech-driven model</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
