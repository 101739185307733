import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Sectionremedart = () => {
  const firstParagraphRef = useRef(null);
  const secondParagraphRef = useRef(null);
  const thirdParagraphRef = useRef(null);
  const fourthParagraphRef = useRef(null);
  const [currentPara, setCurrentPara] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const firstParagraph = firstParagraphRef.current;
      const secondParagraph = secondParagraphRef.current;
      const thirdParagraph = thirdParagraphRef.current;
      const fourthParagraph = fourthParagraphRef.current;

      const firstParagraphIsInViewport = isElementInViewport(firstParagraph);
      const secondParagraphIsInViewport = isElementInViewport(secondParagraph);
      const thirdParagraphIsInViewport = isElementInViewport(thirdParagraph);
      const fourthParagraphIsInViewport = isElementInViewport(fourthParagraph);

      if (firstParagraphIsInViewport) {
        setCurrentPara(1);
      } else if (secondParagraphIsInViewport) {
        setCurrentPara(2);
      } else if (thirdParagraphIsInViewport) {
        setCurrentPara(3);
      } else if (fourthParagraphIsInViewport) {
        setCurrentPara(4);
      } else {
      }
    };

    const isElementInViewport = (element) => {
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      return rect.top < window.innerHeight && rect.bottom > 0;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: 'url("/heroimg6.jpg")',
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="container py-5">
        <div className="row py-5">
        <div className="horizontal-trigger col-md-6">
            <div
              style={{
                position: "sticky",
                top: "100px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src="/tabScreen.png"
                  className="w-100"
                  alt="Outer Image"
                />
                {currentPara === 1 ? (
                  <img
                    id="inner-image"
                    src={"/screen1.png"}
                    alt="Inner Image"
                    style={{
                      position: "absolute",
                      top: "10%",
                      height: "auto",
                      width: "87%",
                      left: "7%",
                    }}
                  />
                ) : currentPara === 2 ? (
                  <img
                    id="inner-image"
                    src={
                      "/screen2.png"
                    
                    }
                    // className="img-fluid"
                    alt="Inner Image"
                    style={{
                      position: "absolute",
                      top: "10%",
                      height: "80%",
                      width: "auto",
                      left: "7%",
                    }}
                  />
                ) : currentPara === 3 ? (
                  <img
                    id="inner-image"
                    src={"/screen3.png"}
                    alt="Inner Image"
                    style={{
                      position: "absolute",
                      top: "10%",
                      height: "auto",
                      width: "87%",
                      left: "7%",
                    }}
                  />
                ) : (
                  <img
                    id="inner-image"
                    src={"/screen4.png"}
                    alt="Inner Image"
                    style={{
                      position: "absolute",
                      top: "10%",
                      height: "auto",
                      width: "87%",
                      left: "7%",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="vertical-trigger col-md-6 px-4">
            <div className="vertical-element pb-5">
              <div ref={firstParagraphRef} className="py-5" >
                {" "}
                <h4 className="hero_subtitle">
                  Consult The Team Of Health Wizards{" "}
                </h4>
                <p  className="text-bold pb-5" data-aos="fade-right">
                  Finding the right health expert at such a crucial time is a
                  reason for worry for you? Every mom-to-be needs special care
                  during this beautifully significant journey of her life. This
                  journey not only changes your body but a lot of other things
                  inside your body. To get you a perfect pregnancy care
                  approach, Myprega is the one-stop solution to your worries. We
                  unfold the insights of the pertinent information about foetus
                  development, other essential changes in the body, and adequate
                  nutrition required and emotional sentiments handled during the
                  entire journey.
                </p>
              </div>
              <div data-target="about" className="ptmore pb-5"  ref={secondParagraphRef} >
                <h4 className="hero_subtitle">Feel Your Baby’s Kick</h4>
                <p className="text-bold pb-5" data-aos="fade-left">
                  Are you willing to maintain a diary to keep your sonography
                  pictures in place? This whole thing is surely very
                  overwhelming for you and you’d not miss a chance to initiate
                  this memorable gesture. To amp up your emotions in tons and
                  feel that kick through the screen, we’ve planned an adequate
                  number of ultrasounds for you so that you can fill these
                  emotional moments with extravaganzas every time you go for
                  sonography.
                </p>
              </div>
              <div data-target="examples" className="pb-5" ref={thirdParagraphRef}>
                <h4 className="hero_subtitle">Reach Your Next Milestone</h4>
                <p
                  
                  className="text-bold pb-5"
                  data-aos="fade-right"
                >
                  Since pregnancy is the most precious phase of your life and
                  during this crucial time, you need to get the essential
                  checkups on time to make sure that your baby is growing well
                  inside. But with all the schedule you have, does it sound like
                  a big task to you? No worries! Our fully automated system
                  supports a health tracker that records oxygen pulse meter
                  reading, keeps track of the growth of your baby through your
                  weight and checks your temperature consistently
                </p>
              </div>
              <div data-target="contact"  ref={fourthParagraphRef}>
                <h4 className="hero_subtitle  pb-5">We Care For You 24x7</h4>
                <p className="text-bold pb-5" data-aos="fade-left">
                  {" "}
                  Feeling those bad contractions and it’s already midnight, will
                  your doctor see you at this time? This is another phase that
                  you’re likely to face in case you’re expecting. Well, for that
                  you’re just a click away. Select the emergency care option and
                  it will alert the health expert about the emergence. Don’t
                  worry, your doctor will see you even if it’s midnight. When we
                  say we care for you, we do it in every aspect. So, that’s why
                  we provide you with a pregnancy care kit that includes an oxy
                  pulse meter, thermometer and baby born kit. As you’re special
                  to us, we let you know that every month by sending you
                  exclusive giveaway baskets that include fruits, nuts and
                  aromatic flowers. It’s our gesture to let you know we’re
                  always there!
                </p>
              </div>
            </div>
          </div>

      
        </div>
      </div>
    </div>
  );
};

export default Sectionremedart;
