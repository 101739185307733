import React from "react";

const Thinkabout = () => {
  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-md-12">
          <h2 className="hero-title text-center">Think about it!</h2>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="left-image">
            <img src="/section1.gif" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="content">
            <h3 className="second-title py-3">
              What makes pregnancy care so complicated and expensive?
            </h3>
            <ul className="expensive-ul">
              <li>
                <img
                  src="/tick.png"
                  alt="tick"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                Lack of transparency & communication with health care providers
              </li>
              <li>
                {" "}
                <img
                  src="/tick.png"
                  alt="tick"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                Insufficient medical education.
              </li>
              <li>
                {" "}
                <img
                  src="/tick.png"
                  alt="tick"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                Non-standardized pricing across locations makes it difficult to
                find the best-suited pregnancy care solutions.
              </li>
              <li>
                {" "}
                <img
                  src="/tick.png"
                  alt="tick"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                Unorganized prenatal care results in feelings of distrust and
                dissatisfaction
              </li>
              <li>
                {" "}
                <img
                  src="/tick.png"
                  alt="tick"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                Prenatal care experiences vary from location to location due to
                the lack of standardized procedures.
              </li>
              <li>
                {" "}
                <img
                  src="/tick.png"
                  alt="tick"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                Lack of technology-based solutions.
              </li>
              <li>
                {" "}
                <img
                  src="/tick.png"
                  alt="tick"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                Treatment-focused care with limited emphasis on prevention.
              </li>
            </ul>
          </div>
          <div className="content">
            <h4 className="hero_subtitle">What can you do about it?</h4>
            <p className="bold-font paracard burgandi">
              Experience pregnancy care with <span className="blue">Myprega</span> once, and we are confident
              you won’t want to go anywhere else!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thinkabout;
