import React from "react";
import CircularProgressBar from "../components/CircularProgressBar";

const Whydoyouneed = () => {
  return (
    <div
      style={{
        backgroundImage: 'url("/heroimg5.jpg")',
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        position: "relative",
      }}
    >

      {/* New section */}

      <div>
      <div className="bg-light py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12">
              <h2 className="hero-title text-center">
                {" "}
                Myprega – Redefining Maternity Care
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="vertical-trigger col-md-6">
              <div>
                <div>

                     <p className="under-subtitle text-bold py-2">
                     <span className="text-[#dc3a7d]">
                       Myprega is India’s first tech-driven, all-in-one pregnancy care solution, offering a complete
                       9-month pregnancy care package from day one of your pregnancy to just before your delivery . 
                      </span>
                        It’s not just care—it’s a revolution in how maternity care is delivered. Think of us as your
                       personalized pregnancy partner, combining cutting-edge technology with compassionate care to
                       support you every step of the way—from the moment you see those two lines on the test until
                       the day you hold your baby in your arms.
                     </p>

                     <p className="under-subtitle text-bold py-2">
                       The best part? You can magically manage your entire pregnancy journey from the comfort of
                       your home, right at your fingertips. What makes us stand out? Unlike traditional setups, where
                       care is often fragmented, Myprega combines technology, empathy, and expertise to offer a
                       seamless experience. With Myprega, you don’t just get access to doctors—you step into a smart
                       ecosystem of care built entirely around you. It’s personalized, transparent & affordable.
                     </p>
                 </div>

                <h2 className="headline2"                 
                style={{
                  fontSize: "40px",
                  fontWeight: "",
                  paddingTop: "50px",
                  paddingBottom: "30px",
                  fontFamily: "Righteous",
                }}>
                With Myprega, you’re not just cared for{" "}
                <span style={{ color: "#dc3a7d" }}>—you’re supported like family, every step of the
                way!
                </span>
                </h2>
                {/* <p className="bold-font paracard burgandi">
                  Pregnancy is a crucial step in your journey to parenthood, and
                  we want to ensure that every aspect is covered.
                </p> */}
              </div>
            </div>
            <div className="horizontal-trigger  col-md-6 order-mobile">
              {/* <img src="/heroimg1.png">
                </img> */}

              <img
                src="/sectionnew.gif"
                style={{
                  objectFit: "cover",
                  objectPosition: "50% 50%",
                  width: "100%",
                  position: "sticky",
                  top: "120px",
                  maxWidth: "800px",
                  margin: "auto",
                }}
                fetchpriority="high"
              ></img>
            </div>
          </div>
        </div>
      </div>

      </div>
      {/* New section */}
      
      <div className="container py-4">
        <div className="row py-3">
          <div className="col-md-12">
            <h2 className="hero-title text-center">
              Why do you need<span className="blue"> myprega.com?</span>
            </h2>
            <h3 className="hero_subtitle underlineone my-2">
              We asked more then thousand of moms and mom buddies
            </h3>

            <p className="bold-font">
              What problems have you faced during your pregnancy care?
            </p>
          </div>
        </div>

        <div className="row circle-item">
          <div className="col-md-12">
            <h4 className="hero_subtitle text-center">This is what we found</h4>
            <div className="row">
              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={96} />
                  </div>
                  <p className="content-paragraph">
                  Current Pregnancy care leaves you disconnected with your doctor on a daily basis.
                  {" "}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={82} />
                  </div>
                  <p className="content-paragraph">
                    Lack of Transparency and Visibility in care act as silent
                    killer during journey{" "}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={71} />
                  </div>
                  <p className="content-paragraph">
                  Pregnancy care can feel fragmented due to the lack of standardization in management, pricing structure, and overall experience
                  {" "}
                  </p>
                </div>
              </div>

              <div className="col-2"></div>
              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={79} />
                  </div>
                  <p className="content-paragraph">
                  Lack of end-to-end management of your complete pregnancy journey at your fingertips
                  {" "}
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={92} />
                  </div>
                  <p className="content-paragraph">
                  Lack of daily pregnancy health tracking and analysis, ensuring early identification of risks{" "}
                  </p>
                </div>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>

        <div className="container py-5">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <h3 className="mb-5 fw-bolder">
                <span className="underlineone text-secondary hero_subtitle">
                  Then We Asked
                </span>
                <br />
              </h3>
              <h3 className="under-subtitle">
                'Are you{" "}
                <span className="blue">satisfied with the solution,</span> that
                you have in age-old pregnancy care?'
              </h3>

              <span className="per mb-4">93% Said </span>
              <span className="per text-danger">NO!</span>
            </div>
            <div className="col-md-6 ">
              <div className="then-we-asked-image">
                <img src="/sadlady.gif" className="sadlady" alt="nt found" />
              </div>
            </div>
          </div>
        </div>
        <div className="container py-3">
          <div className="row">
            <div className="col-md-12">
              <h5 className="note-banner">
                So, together with hundreds of medical professional across, we
                have developed myprega.com
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whydoyouneed;
