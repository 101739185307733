import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const reviews = [
  {
    name: "Nathan D.",
    place: "Germany",
    rating: 4.5,
    image: "https://images.unsplash.com/photo-1488161628813-04466f872be2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
    review: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati eligendi suscipit illum officia ex eos."
  },
  {
    name: "Mary Will",
    place: "Paris",
    rating: 5,
    image: "https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80",
    review: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati eligendi suscipit illum officia ex eos."
  },
  {
    name: "Kevin Tuck",
    place: "New York",
    rating: 4.5,
    image: "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
    review: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati eligendi suscipit illum officia ex eos."
  }
];

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  return (
    <div style={{ display: "flex", color: "rgb(253, 180, 42)", margin: "1rem 0" }}>
      {[...Array(fullStars)].map((_, i) => (
        <FaStar key={i} />
      ))}
      {halfStar && <FaStarHalfAlt />}
    </div>
  );
};

export default function Reviews() {
  return (
    <div style={{ display: "flex", minHeight: "100vh", alignItems: "center", justifyContent: "center", textAlign: "center", flexDirection: "column", maxWidth: "1100px", margin: "auto", padding: "2rem 1rem" }}>
      <h2 style={{ fontSize: "2.5rem", textTransform: "uppercase", color: "rgb(50, 50, 50)", marginBottom: ".5rem" }}>Our Guests Love Us</h2>
      <p style={{ fontSize: "1.1rem", color: "red", fontStyle: "italic" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil laboriosam possimus perferendis non error neque.</p>
      
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", margin: "2rem auto", gap: "1rem" }}>
        {reviews.map((review, index) => (
          <div key={index} style={{ flex: "1 1 300px", backgroundColor: "rgb(238, 238, 238)", padding: "2.5rem", boxShadow: "2px 2px 10px 3px rgb(225, 225, 225)", borderRadius: "10px", maxWidth: "400px" }}>
            <div style={{ margin: "1.75rem auto", width: "150px", height: "150px" }}>
              <img src={review.image} alt={review.name} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%", boxShadow: "0 10px 25px rgba(0, 0, 0, .25)" }} />
            </div>
            <h3 style={{ fontSize: "1.5rem", color: "rgb(50, 50, 50)", marginBottom: ".25rem" }}>{review.name}</h3>
            <p style={{ color: "red", fontStyle: "italic" }}>{review.place}</p>
            <StarRating rating={review.rating} />
            <p style={{ lineHeight: "1.5rem", letterSpacing: "1px", color: "rgb(150, 150, 150)" }}>{review.review}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
